
.mdl-demo .dropdown-trigger .tag-list .tag .tag-remove{
    font-size: 100%;
    color: red;
}

.mdl-demo .dropdown-trigger .tag-list .tag {
    background-color: white;
    padding: 4px;
    border-radius: 15px;
}

.mdl-demo .react-dropdown-tree-select .dropdown {
    display: flex !important;
    flex-direction: column !important;
}

.react-dropdown-tree-select .dropdown .dropdown-trigger {
    border-radius: 10px !important;
    padding: 12px !important;
    border: 1.5px solid #555 !important;
}

.react-dropdown-tree-select .dropdown {
    position: relative;

    display: flex !important;
    flex-direction: column !important;
}


/*.mdl-demo .dropdown-trigger > span:after {*/
/*    font-size: 12px;*/
/*    color: #555;*/
/*}*/

/*.mdl-demo .toggle {*/
/*    font: normal normal normal 18px/1 "Material Icons";*/
/*    color: #555;*/
/*    white-space: pre;*/
/*    margin-right: 4px;*/
/*}*/

/*.mdl-demo .toggle.collapsed::after {*/
/*    cursor: pointer;*/
/*    content: "\E5CF";*/
/*    vertical-align: middle;*/
/*}*/

/*.mdl-demo .toggle.expanded::after {*/
/*    cursor: pointer;*/
/*    content: "\E5CE";*/
/*    vertical-align: middle;*/
/*}*/

/*!* checkbox styles *!*/
/*.mdl-demo .checkbox-item {*/
/*    position: relative;*/
/*    width: 1rem;*/
/*    height: 1rem;*/
/*    margin-right: 0.75rem;*/
/*    cursor: pointer;*/
/*    -webkit-appearance: none;*/
/*    -moz-appearance: none;*/
/*    appearance: none;*/
/*    outline: 0;*/
/*    vertical-align: middle;*/
/*}*/

/*.mdl-demo .checkbox-item:before {*/
/*    content: "";*/
/*    position: absolute;*/
/*    left: 0;*/
/*    top: 0;*/
/*    z-index: 1;*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    border: 2px solid #aaa;*/
/*    transition: all 0.3s ease-in-out;*/
/*}*/

/*.mdl-demo .checkbox-item:checked:before {*/
/*    height: 50%;*/
/*    -webkit-transform: rotate(-45deg);*/
/*    transform: rotate(-45deg);*/
/*    border-top-style: none;*/
/*    border-right-style: none;*/
/*    border-color: #2196f3;*/
/*}*/
