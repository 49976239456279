.expandArrow {
	padding: 3px;
	border-radius: 50%;
	background-color: #ECECEC;
}

.bgAdditionalInfo {
	background: rgba(237, 238, 240, 1);
}

.textEerieBlack {
	color: #1B1B1D;
}

.textSonicSilver {
	color: #848484;
}


.shadow-inset-custom {
	border: 1px solid #B88EFE26;
	box-shadow: 0 0 4px 0 rgba(184, 142, 254, 0.5) inset;
}

.bg-hover-icon {
	background: rgba(184, 142, 254, 0.4);

}

.hover-card-icon {
	height: 44px;
	width: 44px;
	border-radius: 50%;
	background-color: rgba(255, 255, 255, 0.4);
	box-shadow: 0 10px 60px 5px rgba(27, 27, 29, 0.2);
	border: 0.5px solid #1B1B1D
}

