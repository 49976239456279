:root {
	--blue: #3A80F4;
	--red: #F7433A;
	--white: #ffffff;
}


.slick-prev:before,
.slick-next:before {
	color: #9ca3af !important;
	font-size: 25px !important;
}


.page-container {
	width: 21cm;
	/*height: 29.7cm;*/
	padding: 0;
	margin-top: 0;
	page-break-before: always;
}

.slider-arrow {
	position: absolute;
	height: 100%;
	width: 100%;
}

.arrow-btn {
	top: 45%;
	z-index: 1;
}

.next {
	float: right;
}

.task-filter input[type='checkbox'] {
	position: static;
}

.app-checkbox {
	position: static !important;
}

.td-highlight:nth-child(1),
.td-highlight:nth-child(2),
.td-highlight tr {
	/*background-color: #F2F3F4;*/
}

.td-center:nth-child(4) {
	text-align: center;
}

.button {
	width: 250px;
	height: 60px;
	font-size: 16px !important;
	background-color: lightblue !important;
	color: black !important;
	border:black;
	border-radius: 10px;
	cursor: pointer;
	text-align: center;
	font-weight: bold;
}
login-bg {
	background-image: url("../public/img/overlay-1.png");
}

.ag-theme-alpine .custom-header {
	background-color: blue;
	/* Change this to your desired blue color */
	color: white;
	/* Change text color to white for better contrast */
	font-weight: bold;
	/* Optional: Make the text bold */
}

/**/
/* styles.css */
.zoom-on-hover {
	display: inline-block;
	transition: transform 0.3s ease-in-out;
}

.zoom-on-hover:hover {
	transform: scale(1.075);
}

.my-div {
	background: darkgreen;
}

.hover-votercard-shadow {
	outline-style: solid;
	outline-width: thin;
	outline-color: transparent;
	transition: all 700ms ease;
}

.hover-votercard-shadow:hover {
	box-shadow: 0px 10px 60px 5px #1B1B1D33;
	outline-color: rgba(27, 27, 29, 0.8);
}

.accordion-content-close {
	max-height: 0px;
	overflow: hidden;
	transition:max-height  1s;
}

.accordion-content-open {
	max-height: 200px;
	transition:max-height 1s;
}
.scrollbar-hidden::-webkit-scrollbar {
	display: none;
  }

.color-cell {
	width: 30px;
	height: 30px;
	border-radius: 50%;
	cursor: pointer;
}

/* For WebKit browsers (Chrome, Safari, Edge) */
::-webkit-scrollbar {
	width: 10px; /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
	background: #f1f1f1; /* Color of the track */
	border-radius: 50px; /* Rounded corners */
}

::-webkit-scrollbar-thumb {
	background: var(--blue); /* Color of the scrollbar thumb */
	border-radius: 50px; /* Rounded corners */
}

::-webkit-scrollbar-thumb:hover {
	background: var(--red); /* Color of the scrollbar thumb on hover */
}

/* For Firefox */
* {
	scrollbar-width: thin; /* "auto" or "thin" */
	scroll-behavior: smooth;
	scrollbar-gutter: unset;
	scrollbar-color: var(--blue) var(--white); /* thumb and track color */
}