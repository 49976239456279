/* beneficiry section */
.voterBeneficieryCard{
    

    padding: 16px;
    gap: 14px;
   
    border: 1px solid rgba(207, 207, 207, 1);
     border-radius: 24px;
    opacity: 0px;
    background: rgba(255, 255, 255, 1);

}

.textSubColor{
   color: #1B1B1D66;
}
.bgGreen{
    color: rgba(27, 179, 106, 1);

}

.reqStatButton{
width:98px;
height:36px;
border-radius:20px;
border: 1px solid rgba(27, 179, 106, 1);
padding:6px 16px;

}

