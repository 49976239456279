@tailwind base;
h1 {
  @apply text-3xl;
}
h2 {
  @apply text-2xl;
}
h3 {
  @apply text-xl;
}

@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "WorkSans";
  src: url("../public/fonts/WorkSans-Regular.ttf");
}

@font-face {
  font-family: "WorkSansExtraBold";
  src: url("../public/fonts/WorkSans-ExtraBold.ttf");
}

* {
  margin: 0;
  font-family: "WorkSans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  scrollbar-gutter: stable;
}

body h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300;
}

.editor {
  font-family: "Raleway", sans-serif;
}

.boldest {
  font-family: "WorkSansExtraBold", sans-serif;
}
.title {
  /*font-weight: 800;*/
  /*color: #009EA6;*/
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.todo-new {
  font-size: 20px;
  margin-bottom: 30px;
}

.todo-new button {
  font-size: 15px;
}

.todo-new input {
  margin-right: 15px;
}

.todo-item {
  font-size: 20px;
}

.todo-item span {
  width: 350px;
  text-align: left;
  display: inline-block;
}

.MuiAutocomplete-popper {
  z-index: 999999 !important;
}

.open-todos {
  margin-bottom: 30px;
}

.todo-item input {
  margin-left: 15px;
  margin-right: 15px;
}

.min-h-code-50 {
  min-height: 50vh;
}

.custom p {
  line-height: 30px;
}

.page-container {
  width: 21cm;
  min-height: 29.7cm;
  padding: 0;
  margin-top: 0;
  /*page-break-before: always;*/
  page-break-before: always;
  /*overflow: hidden;*/
}

.task-view .timeline-card-content {
  min-height: 100px !important;
}

/********************** CODE JSON ****************************/
.text-code {
  font-family:
    "Courier New",
    Courier,
    Lucida Sans Typewriter,
    Lucida Typewriter,
    monospace;
  color: #1f618d;
}

/************************* REPORT STYLE *********************/
.main-report-container h1 {
  font-size: 20pt;
}

.main-report-container h2 {
  font-size: 18pt;
}

.main-report-container h3 {
  font-size: 16pt;
}

.main-report-container h4 {
  font-size: 15pt;
}

.main-report-container h5 {
  font-size: 14pt;
}

.main-report-container h6 {
  font-size: 13pt;
}

.narrative p,
.narrative ul {
  margin-top: 10px;
}

.narrative ul li {
  list-style-type: disc;
  margin-left: 20px;
}

.narrative ol li {
  list-style-type: number;
  margin-left: 20px;
}

.rnr-data-grid {
  font-family: Montserrat, sans-serif !important;
}

.MuiDataGrid-root.rnr-data-grid {
  background-color: white;
}

.rnr-data-grid .MuiDataGrid-columnHeaderTitle {
  font-weight: bold !important;
}

.rnr-data-grid .MuiDataGrid-columnHeaderWrapper {
  background-color: #efefef !important;
}

/************************* LIBRARIES *********************/

#hot-display-license-info {
  display: none !important;
}

/************************* DATA GRID CUSTOMIZATION ************/

.grid_currrent_row {
  background-color: #f2f5a9 !important;
}

.audiorecorder ._dt3-T {
  min-height: 280px !important;
}
.audiorecorder ._1Yplu {
  display: flex;
  justify-content: center;
}
.audiorecorder._2fG9h {
  width: 100%;
  margin: 0 auto;
  padding: 10px !important;
}
.audiorecorder div audio {
  position: absolute !important;
  margin-top: 224px !important;
  margin-left: -38px;
}
@media only screen and (max-width: 600px) {
  .audiorecorder ._1YOWG {
    width: max-content !important;
  }
  .audiorecorder div audio {
    position: absolute !important;
    margin-top: 224px !important;
    margin-left: -38px;
  }
  .bottom-btn {
    margin-top: 60px;
  }
}
/****************** CASTE DETAILS FOR FSD *******************/

.fsd-caste-details {
  /*display: flow-root;
  column-count: 2;*/
}

.fsd-caste-details .first {
  width: 250px;
  padding-left: 4px;
}
.fsd-caste-details .second {
  width: 110px;
}
.fsd-caste-details .third {
  width: 110px;
}
.fsd-caste-details {
  padding-bottom: 40px;
}
.fsd-caste-details thead {
  border-top: 2px solid black;
  border-bottom: 2px solid black;
  height: 38px;
}

.fsd-caste-details tbody {
  border-bottom: 2px solid black;
}
.fsd-caste-details .level-1 .first {
  padding-left: 40px;
}

.fsd-caste-details .level-1 {
  font-weight: 400;
  border-bottom: dotted 1px gray;
  color: #566573;
}

.fsd-caste-details .level-reg {
  font-weight: 400;
  border-bottom: dotted 1px gray;
}

.fsd-caste-details .caste-header,
.fsd-caste-details .caste_value {
  width: 85px;
  padding: 4px;
  border-left: dotted 1px #b2babb;
  border-right: dotted 1px #b2babb;
}

.fsd-caste-details .first {
  width: 250px;
  padding-left: 4px;
}

.fsd-caste-details .level-0 {
  font-weight: 600;
  border-bottom: dotted 1px gray;
  color: #212f3d;
}

.fsd-caste-details tr td:last-child {
  border-right: none;
}

.fsd-caste-details .region_percent {
  color: #566573;
  font-weight: 500;
}

.fsd-caste-details .region_voters {
  color: black;
  font-weight: 300;
  font-size: 10.5pt;
}

@media only screen and (max-width: 600px) {
}

/***********************************************************/

.all_surveys table th,
.all_surveys table td {
  text-align: left !important;
}

table .active {
  background-color: #9ff9b8 !important;
}

/***********************************************************/

@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
}

/***********************************************************/
.voter-staus-table table {
  border-collapse: collapse;
  background-color: white;
}

.voter-staus-table td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.voter-staus-table tr:nth-child(even) {
  background-color: #dddddd;
}

/***********************************************************/

.booth-icon {
  font-weight: bold;
  font-size: 16px;
  background-color: transparent;
}

.location {
  font-weight: bold;
  font-size: 16px;
  background-color: transparent;
}

/*************************************************************/

.slate-editor ol {
  list-style: decimal;
  margin-left: 32px;
}

.slate-editor ul {
  list-style: disc;
  margin-left: 32px;
}

.slate-editor {
  line-height: 27px;
}

/* grievance management */
@media only screen and (max-width: 1000px) {
  .viewgrievance .main {
    display: flex;
    flex-direction: column;
  }
  /* .viewgrievance .grievancedetails{
      width: 100%;
  }
  .viewgrievance .timelinelog{
      width: 100%;
  }
  .viewgrievance .list-detail{
      width: 100%;
  }*/
  .viewgrievance .task-view {
    width: 100% !important;
    height: 1000px !important;
    margin-top: 10%;
  }
}

/***************************************************************/
.media-carousel-action-btn-pane {
  position: absolute;
  margin-left: auto;
  right: 20px;
  top: 0;
  font-size: 22px;
}
/***************************************************************/

.flexBoxRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.login-bg {
  background-image: url("../public/img/overlay-1.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 100%;
  position: absolute;
}

.fp-bg {
  background-color: #009ea6;
}

.fp-text {
  color: #009ea6;
}

.theme-font {
  color: #009ea6;
}

.formik-select select {
  appearance: auto;
}

.voter-list .chkbox {
  left: 90% !important;
}

.primary-bg {
  background-color: #009ea6;
}

.primary-text {
  color: #009ea6;
}

.overlay-text {
  color: #009ea6;
}

.survey_editor input[type="checkbox"] {
  position: static;
}

.mb-20 {
  margin-bottom: 20px;
}

.w-100 {
  width: 100%;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* Number of lines to show before truncating */
  -webkit-box-orient: vertical;
  /*height: 200px; !* Fixed height of the div *!*/
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.ag-grid-center-text {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto !important;
  font-size: 16px !important;
}

.ag-theme-quartz .ag-header-cell {
  font-size: 18px;
  font-weight: bold;
}

.serviceReqList .ag-header, 
.serviceReqList .ag-header-cell {
  background-color:#3A80F4;
   color: white;
    font-weight: 700;
    
}

.filepond--panel-root {
  background-color: transparent !important; 

}
